.cabinet-product-card {
  position: relative;
  width: 285px;
  transition: background-color 0.3s ease;
  margin: 0 auto;
}

.cabinet-image img {
  width: 285px;
}

.cabinet-details {
  background-color: #f4f5f7;
  height: 200px;
  padding: 2px 0px 0px 10px;
  margin-top: -6px;
}

.cabinet-details h1,
.cabinet-details h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0e3a77;
}

.cabinet-details h1 {
  font-size: 24px;
}

.cabinet-details h3 {
  font-size: 20px;
}

.cabinet-details p {
  font-family: "Poppins", sans-serif;
  color: #898989;
  font-size: 14px;
  width: 270px;
}

.add-to-cart-btn {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 20px;
  width: 12rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  background-color: #ffffff;
  color: #0e3a77;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: none;
}

.cabinet-product-card:hover {
  background-color: #0e3a77;
}

.cabinet-product-card:hover .cabinet-details,
.cabinet-product-card:hover .cabinet-image img {
  opacity: 0.3;
}

.cabinet-product-card:hover .add-to-cart-btn {
  display: block;
}
