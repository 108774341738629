.hero-section {
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  height: 75vh; /* Adjust the height as needed for desktop view */
}

.hero {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 73%;
}

.hero-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 2rem;
  width: 50%;
  max-width: 643px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.new-arrival {
  font-family: "Poppins", sans-serif;
  color: #333333;
  font-weight: 600;
  transform: translateY(-50%);
  font-size: 16px;
  margin-bottom: 2rem;
  letter-spacing: 2px;
}

.hero-container h1 {
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-weight: 800;
  color: #0e3a77;
  width: 500px;
  line-height: 4rem;
  margin-bottom: 0.5rem;
}

.lorem {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3rem;
  width: auto;
  line-height: 25px;
}

.hero-container button {
  padding: 1rem 2.5rem;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 600;
  background-color: #0e3a77;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hero-container button:hover {
  color: white;
  background-color: #365887;
}

/* Responsive adjustments */
@media screen and (max-width: 850px) {
  .hero-img {
    object-position: center 50%; 
  }
  
  .hero-container {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 80%;
    height: auto;
    padding: 2rem;
  }

  .new-arrival {
    font-size: 14px;
    margin-bottom: -1rem;
  }

  .hero-container h1 {
    font-size: 36px;
    width: 100%;
    line-height: 3rem;
  }

  .lorem {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
  }

  .hero-container button {
    padding: 0.5rem 1.2rem;
  }
}

@media (max-width: 500px) {
  .hero-img {
    object-position: center 30%; 
  }
  
  .hero-container {
    padding: 1.5rem;
    width: 90%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .hero-container h1 {
    font-size: 28px;
    line-height: 2.5rem;
  }

  .lorem {
    font-size: 14px;
    line-height: 20px;
  }

  .hero-container button {
    padding: 0.7rem 1.5rem;
  }
}
