  .footer {
    /* footer */
    position: static;
    width: 100% ;
    height: 394px;
    padding: 60px;
    background-color: white;
    color: black;
  }

  .group45 {
    width: 100%;
    height: 272px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .group32 {
    width: 100%;
    height: 118px;
    display: flex ;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .name {
    min-width: 190px;
    width: 100%;
    height: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    display: flex ;
    flex-direction: column; 
  }

  .blue {
    color: #0E3A77;
  }

  .address {
    width: 100%;
    height: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #9F9F9F;
    display: flex ;
    flex-direction: column; 
  }
  
  .gray {
    width: 100%;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: gray;
  }

  .buttonContainer {
    max-width: 316px;
    width: 100%;
    height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 18px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }

  /* button frame */
  .frame24 {
    width: 316px;
    height: 24px;
    display: flex;
    flex-direction: row ;
    align-items: center ;
    justify-content: space-between;
  }

  .emailContainer {
    width: 286px;
    height: 77px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  /*input & button*/
  .emailInput {
    width: 286px;
    height: 18.3px;
    border: 1px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

/* input box */
.form input {
    width: 200px;
    border: 0px;
    border-bottom: 1px solid #000000;
}

.form textarea {
    width: 100%;
    font-family: Roboto, sans-serif;
}

.form button {
    width: 75px;
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16;
    text-decoration: underline;
    text-align: center;
}

.group44 {
  width: 100%;
  height: 46.03px;
  border: 1px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}

.group44text {
  width: 100%;
  height: 18.72px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.line4 {
  width: 100%;
  height: 0px;
  border: 1px 0px 0px 0px;
  border: 1px solid #D9D9D9
}

.text {
  width: 322px;
  height: 100%;
  display: flex ;
  flex-direction: column; 
}

  @media (max-width: 768px) {
    .footer {
      padding: 0;
    }
    .group45{
      height: 100%;
    }
    .group32 {
      height: 100%;
      padding: 10px 0px 10px 0px;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .text {
      height: 33%;
    }
    .gray {
      text-align: center;
    }
    .name {
      text-align: center;
    }
    .address {
      text-align: center;
    }
    .buttonContainer {
      height: 33%;
      justify-content: space-evenly;
    }
    .emailContainer {
      height: 33%;
      justify-content: space-evenly;
    }
    .emailInput {
      height: auto;
      flex-direction: column;
      align-items: center;
    }
    .form button {
      padding: 10px;   
    }
    .group44text {
      text-align: center;
    }

    .confirmation {
      text-align: center;
      padding: auto;  
    }
  }