.shop-hero {
  position: relative;
  text-align: center;
  margin-bottom: -0.4rem !important;
}

.shop-hero img {
  width: 100vw;
  height: 316px;
}

.shop-hero-title,
.shop-hero-home,
.shop-hero-shop {
  font-family: "Poppins", sans-serif;
}

.shop-hero-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 48px;
  color: #0e3a77;
}

.shop-hero-subtitle {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.shop-hero-home {
  font-weight: 600;
  font-size: 16px;
  color: #0e3a77;
  cursor: pointer;
}

.shop-hero-shop {
  font-weight: 300;
  font-size: 16px;
  color: #0e3a77;
}

.cabinet-products {
  max-width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  padding-top: 3.5rem;
  padding-bottom: 2.5rem;
  justify-content: center;
  margin-top: 3rem !important;
}

.control-panel {
  width: 100vw;
  height: 100px;
  background-color: #d2e5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8rem;
  position: relative;
}

.filter-sort {
  display: flex;
  align-items: center;
}

.filter {
  margin-right: 15rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter span {
  margin-left: 0.6rem;
  font-size: 18px;
  font-weight: 500;
  cursor: default;
}

.filter img {
  width: 1.8rem;
}

.results,
.sort-by {
  display: flex;
  align-items: center;
}

.sort-by select {
  margin-left: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.sort-by label {
  font-size: 18px;
  font-weight: 500;
  margin-right: 0.5rem;
}

.filter-dropdown {
  width: 200px;
  background-color: #d2e5f7;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-left: 2rem;
  position: absolute;
  top: 75%;
  left: 7%;
  z-index: 1;
}

.dropdown-header {
  background-color: #f4f5f7;
  padding: 10px 0;
  border-radius: 15px;
  margin-bottom: 10px;
  text-align: center;
  color: #0e3a77;
  font-weight: 800;
}

.dropdown-container {
  display: block;
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
}

.dropdown-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.dropdown-container:hover input ~ .checkmark {
  background-color: #dedede;
}

.dropdown-container input:checked ~ .checkmark {
  background-color: #000000;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.dropdown-container input:checked ~ .checkmark:after {
  display: block;
}

.dropdown-container .checkmark:after {
  top: 7.5px;
  left: 7.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.sort-options {
  font-size: 1rem;
}

@media only screen and (max-width: 1220px) {
  .cabinet-products {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
  .results {
    margin-left: -6rem;
  }
}

@media only screen and (max-width: 1000px) {
  .cabinet-products {
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
  }
  .filter {
    margin-right: 10rem;
  }
  .filter-dropdown {
    left: 3%;
  }
  .control-panel {
    padding: 0 5rem;
  }
  .banner-container {
    height: 220px;
    padding: 0 3%;
  }
  .banner-img img {
    width: 40px;
  }
  .banner-detail h2 {
    margin-top: 0.8rem;
    font-size: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .shop-hero-title {
    font-size: 37px;
  }
  .shop-hero-home {
    font-size: 14px;
  }
  .shop-hero-shop {
    font-size: 14px;
  }
  .filter img {
    width: 1.4rem;
  }
  .filter span {
    font-size: 16px;
  }
  .sort-by label {
    font-size: 16px;
  }
  .sort-options {
    font-size: 0.8rem;
  }
  .results {
    display: none;
  }
  .shop-hero img {
    height: 250px;
  }
}

@media only screen and (max-width: 660px) {
  .shop-hero-title {
    font-size: 33px;
  }
  .shop-hero-home {
    font-size: 12px;
  }
  .shop-hero-shop {
    font-size: 12px;
  }
  .filter {
    margin-right: 5rem;
  }
  .filter-dropdown {
    left: -3%;
  }
  .control-panel {
    padding: 0 2rem;
    height: 80px;
  }
  .cabinet-products {
    grid-template-columns: repeat(1, 1fr);
    gap: 3.5rem;
    margin-top: 0 !important;
  }
  .banner-container {
    height: 150px;
  }
  .banner-img img {
    width: 30px;
  }
  .banner-detail h2 {
    margin-top: 0.3rem;
    font-size: 15px;
  }
  .banner-detail p {
    margin-top: -0.7rem;
    font-size: 10px;
  }
  .shop-hero img {
    height: 220px;
  }
}

@media only screen and (max-width: 520px) {
  .shop-hero-title {
    font-size: 28px;
  }
  .shop-hero-home {
    font-size: 10px;
  }
  .shop-hero-shop {
    font-size: 10px;
  }
  .banner-container {
    height: 120px;
  }
  .banner-img img {
    width: 23px;
  }
  .banner-detail h2 {
    margin-top: 0.1rem;
    font-size: 11.5px;
  }
  .banner-detail p {
    margin-top: -0.6rem;
    font-size: 8px;
  }
  .shop-hero img {
    height: 180px;
  }
}

@media only screen and (max-width: 415px) {
  .filter {
    margin-right: 2rem;
  }
  .control-panel {
    padding: 0 1rem;
    height: 65px;
  }
  .filter img {
    width: 1.2rem;
  }
  .filter span {
    font-size: 14px;
  }
  .sort-by label {
    font-size: 14px;
  }
  .sort-options {
    font-size: 0.7rem;
  }
  .filter-dropdown {
    width: 180px;
    left: -6%;
  }
  .dropdown-header {
    padding: 5px 0;
  }
  .dropdown-container {
    margin-bottom: 15px;
    font-size: 13px;
  }
  .shop-hero img {
    height: 150px;
  }
  .banner-container {
    height: 100px;
  }
  .banner-img img {
    width: 18px;
  }
  .banner-detail h2 {
    margin-top: 0.2rem;
    font-size: 8.5px;
  }
  .banner-detail p {
    margin-top: -0.5rem;
    font-size: 6px;
  }
}
