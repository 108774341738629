.about {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-items:flex-start;*/
}

.about img {
    max-width: 100%;
    width: 100vw;
}

.group178 {
    max-width:1440px;
    width: 130vw;
    height: auto;
    padding: 60px 100px 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.group178text {
    width: 80%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
}

.group177 {
    max-width: 1440px;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.group177 img {
    max-width: 1440px;
    width: 150vw;
    height: auto;
}

.upper {
    max-width: 1440px;
    min-height: 521.61px;
    width: 100vw;
    background: linear-gradient(90deg, #071D3B 2.01%, #0E3A77 6.83%, #0E3A77 49.66%, #071D3B 67.46%, #071D3B 88.64%);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.strongC {
    line-height: 48px;
    font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
}

.collection {
    width: 50%;
    height: auto;
    padding-left: 10%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: #FFFFFF;
}

.model {
    width: 309px;
    height: 523px;
    padding-left: 10%;
}

.group179 {
    max-width: 1440px;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    width: 449px;
    height: auto;
    font-family: Poppins;
    font-size: 48px;
    font-weight: 500;
    line-height: 72px;
    text-align: center;
    color: #0E3A77;
}

.rectangle78 {
    width: 100%;
    height: 4.24px;
    background: #0E3A77;
}

@media (max-width: 768px) {
    .upper {
        flex-direction: column;
    }
    .collection {
        width: 100%;
        padding-right: 10%;
    }
    .model {
        padding-left: 0%;
    }
}