.shop-hero {
  position: relative;
  text-align: center;
  margin-bottom: 3rem;
}

.shop-hero img {
  width: 100vw;
  height: auto;
}

.shop-hero-title,
.shop-hero-home,
.shop-hero-cart {
  font-family: "Poppins", sans-serif;
}

.shop-hero-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 4vw;
  color: #0e3a77;
}

.shop-hero-subtitle {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.shop-hero-home {
  font-weight: 600;
  font-size: 1.3vw;
  color: #0e3a77;
}

.shop-hero-cart {
  font-weight: 300;
  font-size: 1.3vw;
  color: #0e3a77;
}
.shop {
  /* Your CSS styles for the shop section */
  margin: 0 auto;
  width: 100%;
}
.cart-header-container {
  position: relative;
  max-width: 817px;
  width: 70%;
  height: 7vh; /* Adjust as needed */
  top: 65%; /* Adjust the vertical position */
  right: 85%;
  margin-right: 10px;
  margin-left: 10px;
}
.cart-header {
  position: relative;
  max-width: 817px;
  width: 100%;
  height: 100%;
  top: 150%; /* Keep it at the same vertical position */
  left: 165%; /* Adjust the x-axis position */
  background-color: #f2f5ff;
  display: flex;
  align-items: center;
  z-index: 2;
}


.cart-header-text {
  font-size: 1.4vw; /* Adjust font size relative to viewport width */
  font-weight: bold;
  margin-right: 2vw; /* Adjust margin relative to viewport width */
  position: absolute; /* Position text elements absolutely */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
  z-index: 2;
}

.product-text {
  width: 10vw; /* Adjust width relative to viewport width */
  height: 6vh; /* Adjust height relative to viewport height */
  left: 22vw; /* Adjust left position relative to viewport width */
  position: absolute; /* Position text elements absolutely */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.price-text {
  width: 10vw; /* Adjust width relative to viewport width */
  height: 6vh; /* Adjust height relative to viewport height */
  left: 31vw; /* Adjust left position relative to viewport width */
  position: absolute; /* Position text elements absolutely */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.quantity-text {
  width: 10vw; /* Adjust width relative to viewport width */
  height: 6vh; /* Adjust height relative to viewport height */
  left: 39vw; /* Adjust left position relative to viewport width */
  position: absolute; /* Position text elements absolutely */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.subtotal-text {
  width: 10vw; /* Adjust width relative to viewport width */
  height: 6vh; /* Adjust height relative to viewport height */
  left: 46vw; /* Adjust left position relative to viewport width */
  position: absolute; /* Position text elements absolutely */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.cart-total {
  display: inline-block;
  position: absolute;
  max-width: 393px;
  max-height: 390px;
  width: 30vw;
  height: 50vw;
  left: 70vw;
  top: 34vw;
  background-color: #f2f5ff;
  z-index: 2;
}

.cart-totals-title {
  display: inline-block;
  position: relative;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 2.5vw;
  width: 50%;
  height: 15%;
  top: 10%;
  left: 45%;
  transform: translate(-30%, 10%);
  font-weight: 600;
  z-index: 2;
}

.subtotal-label {
  display: inline-block;
  position: absolute;
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 1.3vw;
  width: 15%;
  height: 24px;
  top: 30%;
  left: 15%;
  font-weight: 500;
  z-index: 2;
}

.subtotal-value {
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #9F9F9F;
  font-size: 1.5vw;
  width: 15%;
  height: 24px;
  top: 25%;
  left: 0%;
  z-index: 2;
}

.total-label {
  position: relative;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 1.3vw;
  width: 15%;
  height: 24px;
  top: 45%;
  right: 43%;
  z-index: 2;
}

.total-value {
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #0E3A77;
  font-size: 1.7vw;
  width: 25%;
  height: 30px;
  top: 33%;
  left: 50%;
  font-weight: 500;
  z-index: 2;
}

.checkout-button {
  position: absolute;
  width: 30%; /* Adjust as needed */
  max-width: 222px; /* Set a max-width to prevent it from growing too large on larger screens */
  height: 13%; /* Adjust as needed */
  width: 50%;
  max-height: 58.95px; /* Set a max-height to prevent it from growing too large on larger screens */
  top: 75%;
  left: 50%;
  border: 1px solid #000000;
  border-radius: 15px;
  background-color: transparent; /* Use transparent to remove background color */
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 1.95vw; /* Adjust the value as needed */
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  transform: translate(-50%, -50%);
  line-height: 40.95px;
}

.checkout-button:hover {
  background-color: #e6ebff;
}

.cart-container {
  position: relative;
  width: 817px;
  height: 100%;
  min-height: 50vh;
  margin-top: 10%;
  left: 10%;
  z-index: 1;
  overflow-y: auto;
}

.cart-scrollable {
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 1144px) {
  .cart-header-text {
    font-size: 1.7vw; /* Adjust font size relative to viewport width */
  }

  .product-text {
    left: 28vw;
  }

  .price-text {
    left: 40vw;
  }

  .quantity-text {
    left: 49vw;
  }

  .subtotal-text {
    left: 60vw;
  }
}

@media (max-width: 1400px) {
  .cart-header {
    position: relative;
    top: 0;
    left: 145%;
    margin-top: 3%;
    transition: none;
  }

  .cart-container {
    margin: 5% auto;
    width: 80%;
  }
  .cart-total {
    position: relative;
    max-width: 90vw;
    width: 100%;
    max-height: 390px;
    top: 15%;
    margin-top: 15%; /* Adjust the distance from the top as needed */
    left: 5%; /* Adjust left position relative to the viewport */
  }
  .cart-totals-title {
    font-size: 3.5vw;
    width: 40%;
    height: 15%;
    top: 6%;
    left: 60%;
    transform: translateX(-50%); /* Center horizontally */
  }

  .subtotal-label {
    font-size: 3vw;
    width: 15%;
    height: 24px;
    top: 30%;
    left: 15%;
    font-weight: 500;
    z-index: 2;
  }

  .subtotal-value {
    font-size: 3vw;
    width: 15%;
    height: 24px;
    top: 27%;
    left: 28%;
  }

  .total-label {
    font-size: 3vw;
    width: 15%;
    height: 24px;
    top: 45%;
    right: 40%;
  }

  .total-value {
    font-size: 3.2vw;
    width: 20%;
    height: 30px;
    top: 45%;
    left: -2%;
    font-weight: 500;
    z-index: 2;
  }
}

@media (max-width: 990px) {
  .checkout-button {
    font-size: 2.5vw;
    line-height: 35px;
  }
}