/* DesignInspiration.css*/

.designInspiration {
  width: 100%;
  text-align: center;
  background-color: #A6CBF0;
  padding-bottom: 2rem;
}

.textSection {
  margin-bottom: 20px;
}

.sectionTitle {
  width: 100%;
  height: 87px;
  margin: 2rem auto;
  font-size: 2.2rem;
  font-weight: bolder;
  /* Automatically center */
  padding: 0px;
}

.sectionDescription {
  width: 100%;
  margin: 0 auto;
  opacity: 0.7;
}

.swiperContainer {
  /* Full width to be responsive */
  height: auto;
  padding-bottom: 1rem;
}

.swiperSlide img {
  display: block;
  max-width: 236px;
  width: auto;
  height: auto;
  object-fit: cover;
}


.swiperItem {
  width: 324px;
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}

.callToAction1 button,
.callToAction2 button {
  padding: 10px 20px;
  margin-top: 1rem;
  font-size: 16px;
  cursor: pointer;
  background-color: #0E3A77;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

.callToAction1 {
  display: none;
}

@media (min-width: 768px) {

  .designInspiration {
    display: flex;
    height: 670px;
    text-align: left;
    padding-top: 2rem;
    bottom: 2rem;
  }

  .textSection {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 200px;
    vertical-align: middle;
  }

  .textSection .sectionTitle {
    font-size: 40px;
  }

  .textSection .sectionDescription {
    font-size: 16px;
  }

  .swiperItem {
    height: 582px;
    width: 404px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .callToAction1 {
    display: block;
  }

  .callToAction2 {
    display: none;
  }
}