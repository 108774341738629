  .header {
    position: static;
    height: 100px;
    width: 100% ;
    padding: 0 2rem ;
    background-color: white ;
    color: black ;
  
    display: flex ;
    flex-direction: row ;
    align-items: center ;
    justify-content: space-between ;
  }

  .logo {
    width:69px ;
    height:72px;
  }
  
  .buttonNav {
    background-color: white !important;
    border: none !important;
    color: black !important;
    font-size: 18px !important;
    padding: 5% !important;
    text-decoration: none !important;
    display: inline-block !important;
    cursor: pointer !important;
  }

  .icon {
    color: #0E3A77;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 39.01px;
    text-align: center;
  }

  .button {
    background-color: white;
    border: none;
    color: black;
    font-size: 18px;
    padding: 5%;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  .buttonContainer {
    width: 316px;
    height: 24px;
    display: flex;
    flex-direction: row ;
    align-items: center ;
    justify-content: space-between;
  }

  .topRightContainer {
    display: flex;
    flex-direction: row ;
    align-items: center ;
    justify-content: space-between;
  }

  .cartButton {
    width: 87px;
    height: 30px;
    padding: 6px 30px 6px 30px;
    gap: 10px;
    border: 1px solid black;
    background-color: white;
    border-radius: 50px;
    color: black;
    font-size: 12px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    
  }
  /* Rectangle 60*/
  /* Rectangle 60*/
  .cartPopout {
    width: 417px;
    max-height: 746px;
    height: auto;
    padding: 10px 40px 10px 40px;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
  }

  .cartflex {
    width: 100%;
    padding-top: 19px;
    padding-bottom: 19px;
    width: 100%;
    padding-top: 19px;
    padding-bottom: 19px;
    display:flex; 
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    align-items:center;
    justify-content: space-between;
  }
  /* Group 149 */
  /* Group 149 */
  .cartitem {
    width: 347px;
    height: 105px;
  }

  .group150 {
    width: 350px;
    height: auto;
    border: 1px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
  }

  .subtotal {
    width: 68px;
    height: 24px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  
  .subtotalcount {
    width: 117px;
    height: 24px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    color: #0E3A77;
  }
  
  .cartButtons {
    margin: auto;
    width: 50%;
    border: 3px;
    padding: 10px;
    text-align: center;
    align-items: center ;
    justify-content: space-between;
  }

  .shoppingCart {
    width: 177px;
    height: 36px;

    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
  }

  .line11 {
    width: 287px;
    height: 0px;
    border: 1px 0px 0px 0px;
    border: 1px solid #D9D9D9;
  }

  .line12 {
    width: 417px;
    height: 0px;
    border: 1px 0px 0px 0px;
    border: 1px solid #D9D9D9;
  }
  
.frame168 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.frame178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.menu{
  display: none;
  align-self: center;
}

.myLinks ul{
  display: flex;
  flex-wrap: wrap;
  float: right;
  margin: 20 0px;
  padding: 0 25px;
}

.myLinks ul li{
  list-style-type: none;
  padding-right: 10px;
}

.cartContainer {
  height: 335px;
  height: auto;
  z-index: 1;
  overflow-y: auto;
  padding-top: 19px;
}

.cartScrollable {
  max-height: 420px;
  height: 100%; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
}

  /* Header */

@media (max-width: 850px) {
  .header {
    height: 100px;
  }
  .buttonContainer{
    display: none;
  }
  .menu{
    display:block;
  }
  .icon {
    margin-left: .8rem;
  }
  .frame168 {
    width: 5rem;
  }
  .frame178 {
    display: none;
  }
}

@media (max-width: 500px) {
  .header {
      height: 100px;
  }
  .buttonContainer{
    display: none;
  }
  .frame178 {
    display: none;
  }
  .icon {
    display: none;
  }
  .menu{
    display:block;
  }
  .frame168 {
    width: 5rem;
  }
}
