.contactSection {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 50px;
}

.header {
    font-size: 36px; 
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;

    text-align: center;
	font-size: 40px;
	font-weight: bolder;
	font-family: "Lato", sans-serif;
	color: #2d385e;
	margin-bottom: 20px;
}

.paragraph {
    margin: auto;
    width: 50%; 
    font-size: 16px;  
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #9f9f9f;
	font-family: "Lato", sans-serif;
	margin-bottom: 20px;
    flex-direction: column;
}

.contactContainer {
    display: flex;
    justify-content: space-between;
    max-width: 55vw;
    margin: 60px auto 0 auto;
}

.contactInfo {
    flex: 1;
    min-width: 300px; 
}

.contactInfo strong {
    font-size: 24px; 
}

.contactInfo > div {
    margin-bottom: 60px;
}

.contactInfo > div > svg {
    margin-right: 10px;
}

.contactInfo p {
    font-size: 16px; 
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 34px; 
    white-space: pre-wrap;
}

.form {
    width: 100%;
	margin-bottom: 15px;
	border-radius: 5px;
	padding-left: 10px;
	font-size: 17px;
}


.form input, .form textarea {
    width: 100%;
    padding: 16px; 
    margin-top: 20px;
    margin-bottom: 40px; 
    border: 1px solid #9f9f9f;
    border-radius: 5px;
}

.form textarea {
    height: 105px; 
    font-family: Roboto, sans-serif;
}

.form button {
    width: 200px; 
    height: 41px;
    background-color: #0E3A77;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem; 
    text-align: center;
    margin-left: 10px; 
}


@media (max-width: 850px) {
     .header, .paragraph {
        font-size: 24px; /* Adjusted for readability on small screens */
        max-width: 100%;
        width: 100%;
    }

    .paragraph {
        font-size: 14px;
        max-width: 85%; 
        width: 50%;
      text-align: center;
      color: #9F9F9F;
      flex-direction: column;
      text-align: center;
      
    }
    .contactContainer {
        flex-direction: column-reverse;
        align-items: center;
        height: 100%;
    }

    .contactInfo {
        padding-top: 100px;
        width: min-content;
    }

    .contactInfo > div {
        flex-direction: column;
        align-items: center;
    }

    .contactInfo p {
        padding-left: 0;
    }

    .phone, .working-time {
        width: 100%; /* Ensure each child takes full width */
        text-align: center; /* Center the text within each block */
        display: flex; /* Use flexbox for alignment */
        flex-direction: column; /* Stack contents vertically */
        align-items: center; /* Center content horizontally */
        justify-content: center; /* Center content vertically */
        margin-bottom: 20px; /* Space between items */
    }



    .form {
        width: 80%;
    }

    .form input, .form textarea {
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 40px;
        border: 1px solid #9f9f9f;
        border-radius: 5px; 
     } 

    .form button {
       
        width: 50%;
        padding: 10px;
    } 
}       

