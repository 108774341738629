.browse-range {
  text-align: center;
  max-width: 1760px;
  margin: 0 auto;
  margin-bottom: 6rem;
  position: relative; /* Necessary for absolute positioning of children */
}

.browse-title  h1 {
  font-family: "Poppins", sans-serif;
  color: #0e3a77;
  margin-top: 3rem;
  margin-bottom: 6rem;
  font-size: 40px;
  font-weight: 800;
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  overflow: hidden; /* In case images go out of bounds, they will be clipped */
}

.image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap to next line */
  gap: 30px;
  justify-content: center;
  position: relative; /* Necessary for positioning */
}

.image-wrapper > * {
  flex-shrink: 0; /* Prevent images from shrinking */
}

.title-bar {
  text-align: center;
  color: black;
  padding: 5px;
  text-decoration: none !important;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 505;
}

.link-bar { /*Class to directly apply text-decoration to the links*/
  text-decoration: none;
}

.browseImg {
  width: 320px;
  height: 450px;
  object-fit: cover; /* Cover to ensure the images cover their area */
  cursor: pointer; /* Indicates clickable images */
  top: 592px;
  left: 186px;
  gap: 0px;
}

.browseImgContainer {
  position: relative;
}

.browseImgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 92.5%;
  background-color: #0e3a77;
  opacity: 0; 
  transition: opacity 0.3s ease; 
}

.browseImgContainer:hover .browseImgOverlay  {
  opacity: .8;
}

/* Browse image text hover */
.browseImgText {
  visibility: hidden;
  background-color: white;
  color: #0e3a77;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-block: 30px;
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 50%;
  padding: 8px 30px;
  transform: translate(-50%, 50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.browseImgContainer:hover .browseImgText {
    visibility: visible;
    opacity: 1;
}

.explore-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  /* Style the button as needed */
}

/* Non-responsive styles */
.element {
  width: 100%;
  padding: 20px;
}

/* Media queries */
@media (max-width: 1200px) {
  .browse-range {
    max-width: 100%;
  }
}

/*@media (max-width: 850px) {
  .browse-range {
    padding: 0 20px;
  }

  .image-wrapper > * {
    width: 100%;
    max-width: calc(50% - 15px);
  }

}

*/
@media (max-width: 500px) {
  .imageL, .imageM, .imageR {
    width: 20%rem;
    height: 20%rem;
    max-height: 90%;
    max-width: 70%;
  }
}
