/* Title styling */
.shop-hero-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px; 
}

.shop-hero {
    position: relative;
    text-align: center;
    color: white;
}

.shop-hero img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* Subtitle styling */
.shop-hero-subtitle {
    position: absolute;
    top: calc(50% + 2.5rem); 
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    margin-top: 10px; 
}
.shop-hero-shop {
    font-weight: normal;
}

.shop-hero-home {
    font-weight: 600;
}

.show-more-link {
    color: white;
    text-decoration: none;
}

/* Mobile styles */
@media (max-width: 768px) {
    .shop-hero-title {
        font-size: 1.5rem;
    }

    .shop-hero-subtitle {
        font-size: 0.875rem;
        top: calc(30% + 2rem); 
    }
    
}
