/* Navigation Bar */
.collectionTab {
    background-color: #d2e5f7;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 5px 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.collectionTab button {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: black;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.collectionTab button:hover {
    background-color: #9dc3e6;
    color: #333333; 
}

.collectionTab button.active {
    border-bottom: 5px solid #0e3a77;
}

/* Collection Containers */
.autaeuroContainer, .doorStylesContainerAutaeuro,
.capitalContainer, .doorStylesContainerCapital,
.adaContainer {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    color: white;
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
}

.autaeuroContainer {
    background: conic-gradient(from 90deg at 21.25% 34.166666666666664%, #37201C 0%, #6D3F37 100%);
}
.doorStylesContainerAutaeuro {
    background: radial-gradient(circle at 51% 57%, #6D3F37 31.57894736842105%, #37201C 60.15625%);
}

.capitalContainer {
    background: radial-gradient(circle at 50% 50%, #0E3A77 0%, #0E3A77 60.15625%, #071D3B 100%);
}
.doorStylesContainerCapital {
    background: radial-gradient(circle at 50% 50%, #0E3A77 0%, #0E3A77 60.15625%, #071D3B 100%);
}

.adaContainer {
    background: linear-gradient(#3c2828 2.01%, #524646 6.83%, #5d5656 49.66%, #140909 67.46%, #6a6262 88.64%);
    text-align: center;
    height: 100%; 
}

.adaPlaceholder {
    padding: 20px;
    margin-bottom: 400px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: black;
}

/* Collection Name and Description */
.collectionName {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 40px; 
    margin-bottom: 30px; 
}

.collectionDescriptionContainer {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 10px 20px;
}

/* Bulleted List */
ul.a {
    list-style-type: disc;
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    font-size: 15px;
}

/* Closer Look Image */
.closerLookImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.closerLookImg {
    max-width: 45%; 
    height: auto;
    margin-top: 30px; 
    border-radius: 8px; 
}

/* Under Construction Image */
.underConstructionImgContainer {
    padding-top: 10vw; 
    padding-bottom: 2vw; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.underConstructionImg {
    max-width: 15%;
    width: 15%;
    margin-top: 40px; 
    margin-bottom: 25px;
    align-self: center;
}

/* Door Images */
.allContainer {
    justify-content: center;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    padding-bottom: 8px;
}

.miniDescription {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: white;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 10px;
    white-space: pre-line;
}
.doorTypeHeader {
    margin-bottom: 20px;
}

.doorFrontImagesContainer {
    justify-content: center;
    gap: 35px; 
    align-items: normal;
    display: flex;
    flex-direction: row;
}

.doorImgContainer {
    position: relative; 
    cursor: pointer; 
}

.doorImg {
    display: block;
    width: 100%; 
    max-width: 120px; 
    height: 100%; 
    transition: filter 0.3s ease, border-color 0.3s ease; 
    border: 4px solid transparent;
}

.doorImgContainer:hover .doorImg {
    filter: brightness(.5);
    transform: scale(1.1);
}

.doorImgText {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    bottom: 50%;
    left: 50%; 
    transform: translate(-50%, 50%); 
    white-space: wrap;
    opacity: 0;
    transition: opacity 0.3s;
}

.doorImgContainer:hover .doorImgText {
    visibility: visible;
    opacity: 1;
}

/* Vertical Line */
.verticalLine {
    border-left: 1px dotted rgb(132, 160, 216);
    height: auto;
    margin-top: 50px;
    margin-bottom: 20px;
}

/* Mobile Compatibility */
@media (max-width: 768px) {
    .collectionTab {
        flex-direction: row;
        gap: 10px;
        padding: 10px 0;
    }

    .collectionTab button {
        font-size: 14px;
        padding: 8px 16px;
    }

    .collectionName {
        font-size: 30px;
    }

    .collectionDescriptionContainer {
        padding: 10px;
    }

    ul.a {
        font-size: 12px;
    }

    .doorTypeHeader {
        font-size: 22px;
        margin-top: 10%;
    }

    .doorFrontImagesContainer {
        flex-direction: row;
        flex-wrap: wrap; 
        justify-content: center; 
        gap: 10px;
    }

    .doorImgContainer {
        flex: 1 0 45%; 
        max-width: 120px;
        margin: 10px; 
    }

    .doorImg {
        max-width: 95%;
        max-height: 200px;
    }

    .doorImgText {
        font-size: 15px;
        justify-content: center;
    }

    .closerLookImg {
        max-width: 60%;
    }

    .underConstructionImg {
        max-width: 50%;
    }

    .verticalLine {
        display: none;
    }
}
